/* global $ */

class Home
{
    constructor () 
    {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            // $('.slick-carousel').slick();
            $(".slickslider").not('.slick-initialized').slick()
        });
        console.log('Home initialised');
    }
}

new Home;